<template>
  <h-table :options="options"> </h-table>
</template>
<script>
import hTable from "@/components/hTable";

export default {
  components: {
    hTable,
  },
  data() {
    return {
      searchData: {
        input: "",
        cascader: [],
        select: "",
        date: null,
      },
      page: {
        pageNo: 1,
        pageSize: 12,
        total: 0,
        // size:'small'
      },
    };
  },
  computed: {
    options() {
      return {
        search: {
          model: this.searchData,
          rules: {
            input: {
              name: "名字",
              type: "i",
            },
            cascader: {
              name: "名字",
              type: "c",
              data: [
                {
                  value: "1",
                  label: "测试1",
                  children: [
                    {
                      value: "1-1",
                      label: "下级1",
                    },
                  ],
                },
              ],
            },
            select: {
              name: "名字",
              type: "s",
              data: [
                {
                  value: "1",
                  label: "测试1",
                },
                {
                  value: "2",
                  label: "测试2",
                },
              ],
            },
            date: {
              name: "名字",
              type: "d",
            },
          },
        },
        add: {
          text: "新增测试",
          callback: this.add,
        },
        page: this.page,
      };
    },
  },
  methods: {
    add() {},
  },
};
</script>
<style lang='less' scoped>
@import url("./index.less");
</style>